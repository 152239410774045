import React, { FC } from 'react';
import { PageProps } from 'gatsby';
import Seo from '../components/Seo';
import PageLayout from '../components/PageLayout';

const pageName = 'Privacy Policy';

const PrivacyPolicy: FC<PageProps> = ({ path }) => {
    return (
        <PageLayout path={path} >
            <Seo
                title='Privacy Policy | Flipmyfile.com'
                propMetaDescription='This is privacy policy of flipmyfile.com'
                indexing={false}
            />

            <h1>This is {pageName} page</h1>

        </PageLayout>
    )
};

export default PrivacyPolicy;
